<template>
  <div>
    <v-snackbar :style="{ 'margin-bottom': calcMargin(i) }" v-for="(s, i) in snackbars" :key="i" v-model="snackbar" :timeout="s.time" :color="s.color">
      {{ s.text }}
      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="hide(i)"> Close </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
export default {
  data: function () {
    return {
      snackbar: false,
      text: null,
      timer: null,
      color: null,
      snackbars: [],
    };
  },
  destoyed() {
    this.$root.$off("websocket", this.addData);
  },
  created() {
    this.$root.$on("websocket", this.addData);

    this.$root.$on("show-info", data => {
      let type = data?.type || "info";
      let time = data?.time || (type == "error" ? 6000 : 2000);
      //console.log("showInfo", data, time);

      this.snackbar = true;
      let text = data.text;

      let color = data.color ? data.color : "red";
      if (type == "info") color = "success";
      this.addAlert({ text, type, time, color });

      /*       clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        this.text = "";
        this.snackbar = false;
      }, time);
 */
    });
  },
  methods: {
    addData(data) {
      //console.log("add data", data);

      if (data.e && data.e == "events") {
        //console.log("addData ok", data.d);
        this.$store.dispatch("state/badges");
        this.$store.dispatch("state/newComment");
        //this.$root.$emit("newComment", data);
        this.notifyMe(data.d.title);
      }
      if (data?.model == "PayOrderModel") {
        this.$store.dispatch("state/badges", "payorder");
      }
    },

    notifyMe(e) {
      this.$root.$emit("show-info", {
        text: e,
        color: "blue lighten-1",
        type: "notify",
        time: 10000,
      });
      e = "! " + e;
      // Let's check if the browser supports notifications
      if (!("Notification" in window)) {
        console.log("Notification not in window");

        alert("This browser does not support desktop notification");
      }

      // Let's check whether notification permissions have already been granted
      else if (Notification.permission === "granted") {
        console.log("Notification granted", Notification.permission);

        // If it's okay let's create a notification
        //var notification = new Notification(e);
      }

      // Otherwise, we need to ask the user for permission
      else if (Notification.permission === "denied") {
        console.log("Notification requestPermission");
        Notification.requestPermission().then(function (permission) {
          console.log("Notification requestPermission ok");
          // If the user accepts, let's create a notification
          if (permission === "granted") {
            // var notification = new Notification(e);
          }
        });
      }

      // At last, if the user has denied notifications, and you
      // want to be respectful there is no need to bother them anymore.
    },
    calcMargin(i) {
      return i * 50 + "px";
    },
    hide(i) {
      this.snackbars.splice(i, 1);
    },
    addAlert(data) {
      const id = Date.now();
      data.id = id;
      this.snackbars = [...this.snackbars, data];
      setTimeout(() => this.clearAlert(id), data.time);
    },
    clearAlert(id) {
      this.snackbars = this.snackbars.filter(alert => alert.id !== id);
    },
  },
};
</script>
<style lang="scss" scoped>
.alerts {
  position: fixed;
  right: 10px;
  bottom: 0;
  z-index: 99999;
}
</style>
