/** @format */

import objects from './modules/objects';
import clients from './modules/clients';
import investors from './modules/investors';
import finance from './modules/finance';
import rooms from './modules/rooms';
import buying from './modules/buying';
import projects from './modules/projects';
import considers from './modules/considers';
import lampObjects from './modules/lampObjects';
import sales from './modules/sales';
import site from './modules/site';
import leads from './modules/leads';
import works from './modules/works';
import goods from './modules/goods';
import payOrder from './modules/payOrder';
import objectReports from './modules/objectReports';
import objectClose from './modules/objectClose';
import furnitureReports from './modules/furnitureReports';

import rent from './modules/rent';
import info from './modules/info';
import invite from './modules/invite';
import accounts from './modules/accounts';
import contracts from './modules/contracts';
import documentOrderGoods from './modules/documentOrderGoods';
import documentOrderService from './modules/documentOrderService';
import documentObjectBuy from './modules/documentObjectBuy';
import documentObjectSell from './modules/documentObjectSell';
import documentGoodsMoving from './modules/documentGoodsMoving';
import documentGoodsReserve from './modules/documentGoodsReserve';
import documentGoodsIncome from './modules/documentGoodsIncome';
import documentActWork from './modules/documentActWork';
import documentActWorkClient from './modules/documentActWorkClient';
import documentExpenses from './modules/documentExpenses';
import documentLoanGet from './modules/documentLoanGet';
import documentEstimate from './modules/documentEstimate';

import dirs from './modules/dirs';
import accRecords from './modules/accRecords';
import cashOut from './modules/cashOut';
import cashIn from './modules/cashIn';
import cashBook from './modules/cashBook';
import cashRemainder from './modules/cashRemainder';
import uploadsGroup from './modules/uploadsGroup';
import storageGoods from './modules/storageGoods';
import accReportAccount from './modules/accReportAccount';
import crypt from './modules/crypt';

//cashRemainder
const models = {
  rent,
  accReportAccount,
  storageGoods,
  uploadsGroup,
  cashIn,
  cashOut,
  cashBook,
  cashRemainder,
  accRecords,
  dirs,
  documentGoodsIncome,
  documentGoodsMoving,
  documentGoodsReserve,
  documentObjectBuy,
  documentObjectSell,
  documentOrderService,
  documentOrderGoods,
  documentActWork,
  documentActWorkClient,
  documentExpenses,
  documentLoanGet,
  documentEstimate,
  accounts,
  goods,
  objects,
  objectClose,
  clients,
  rooms,
  considers,
  lampObjects,
  projects,
  sales,
  site,
  investors,
  leads,
  works,
  info,
  objectReports,
  furnitureReports,
  buying,
  contracts,
  crypt,
  invite,
  payOrder,
};

export { models };
