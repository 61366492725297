/** @format */

export default {
  methods: {
    getAccess(name, config = null, def = false) {
      const o = name.split('.');
      let conf = config || {};
      let access = {};
      let tmp;
      for (let i = 0; i < o.length; i++) {
        const el = o[i];
        if (i === 0) {
          tmp = JSON.parse(JSON.stringify(this.$store.getters['config/get'].config.access || {}));
        }
        tmp = tmp?.[el];
      }
      access = tmp; 
      let res = def;
      if (!access) {
        //  console.log('object access not found', name, access);
        access = {};
        res = def;
      } else {
        res = false;
      }
      //console.log('getAccess', name, access);
      const role = this.$root.profile.role;
      const user = this.$root.profile.id;
      res = this.checkAccess(access,  conf, role, user, def);
      //console.log('getAccess ' + name, { role, res }, access);
      return res;
    },
    checkAccess(access,  conf, role, user, def) {
      let res = def;
      if (access?.role?.length) {
        res = access.role.includes(role) || false;
        if (!res) {
          res = access.role.includes(-1) && !access.role.includes(-role) && !(access?.user && access?.user.includes(-user));
        }
      }
      if (!res && access?.user?.length) res = res || access?.user.includes(user) || false;
      if (!res && access?.inRole?.length && conf?.access) {
        //console.log('getAccess', name, access, res);
        conf.access.forEach((el) => {
          res = res || (user == el.user_id && access.inRole.includes(el.user_role)) || false;
        });
      }
      if (!res && conf?.access && !access?.inRole?.length && conf?.inRole == -1) {
        //отключил сделал inRole=-1
        if (
          conf.access.find((el) => {
            return el.user_id == user;
          })
        ) {
          res = true;
          //console.log('conf?.access !inrole', conf, res);
        }
      }
      if (!res && conf?.users) {
        // console.log('conf?.users', conf, res);
        res = conf.users.includes(user) || false;
      }
      if (!res && conf?.data) {
        if (conf.data?.createdby_id == user) {
          res = true;
          //console.log('conf?.data', conf, res);
        }
      }
      if (!res && 0 == 1) {
        res = this.getAccessId(name, conf.id);
      }
      return res;
    },
    async getAccessId(obj, id) {
      if (obj == 'editObject') {
        const d = await this.$axios.get('/mechti/objects/' + id);
        if (d.data && d.data.data) {
          const user = this.$root.profile.id;
          if (d.data.data.object_access?.[obj]?.role.includes(user)) res = true;
        }
      }
    },
  },
};
