<template>
  <div v-if="info.length > 0" class="mr-3">
    <v-menu bottom offset-y transition="scale-transition" origin="center center">
      <template v-slot:activator="{ on, attrs }">
        <v-icon large v-bind="attrs" v-on="on">mdi-school-outline</v-icon>
      </template>
      <v-list>
        <v-list-item v-for="el in info" :key="el.id" @click="onClickInfo(el.id)">
          <v-list-item-title> {{ el.name }} </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
    <portal to="v-main">
      <InfoView v-model="showEditDialog" :id="idInfo" old />
    </portal>
  </div>
</template>

<script>
export default { 
  components: {
    InfoView: () => import("@/views/dialogs/infoViewDialog"),
  },
  props: {
    name: String,
  },
  data() {
    return {
      info: [],
      idInfo: 0,
      showEditDialog: false,
    };
  },
  destoyed() {},
  watch: {
    name(v) {
      if (v) {
        this.getInfo();
      }
    },
  },
  created() {
    this.getInfo();
  },
  methods: {
    onClickInfo(id) {
      this.idInfo = id;
      this.showEditDialog = true;
    },
    async getInfo() {
      const resp = await this.$axios.post("accounting/records/report-sql", {
        q: `SELECT id,name from information WHERE  
JSON_EXTRACT(data,'$.section') IS NOT null and (FIND_IN_SET('${this.name}',JSON_VALUE(data,'$.section')) > 0  or JSON_CONTAINS(JSON_EXTRACT(data, '$.section'), '"${this.name}"'))
and  (FIND_IN_SET('${this.$root.profile.role}',JSON_VALUE(data,'$.role')) > 0 or FIND_IN_SET('-1',JSON_VALUE(data,'$.role')) > 0 or JSON_CONTAINS(JSON_EXTRACT(data, '$.role'), '${this.$root.profile.role}'))`,
      });
      if (resp.data.status == "ok") {
        this.info = resp.data.data || [];
      }
    },
  },
};
</script>
<style lang="scss" scoped></style>
