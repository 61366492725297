/** @format */
const editable = true;
//contactPhone
const site = {
  types: [
    { value: 1, text: '1', default: true, show: false },
    { value: 2, text: 'Cайт' },
    { value: 3, text: 'Авито' },
    { value: 4, text: 'Циан' },
  ],
  objects: 'id,name,city,residential_complex_id,ap_area,ap_rooms,ap_price_buy,type,class,status,status_site',
  lampObjects:
    'id,name,city,residential_complex_id,ap_area,ap_rooms,ap_price_buy,type,ads_data.versionAvito,ads_data.versionCian,ads_data.contactPhone,ads_data.contactPhone_cian,status,status_site',
  agentObjects:
    'id,name,city,residential_complex_id,ap_area,ap_rooms,ap_price_buy,type,ads_data.versionAvito,ads_data.versionCian,ads_data.contactPhone,ads_data.contactPhone_cian,status,status_site',
  list: [
    { name: 'id', title: '#', type: 'id', sortable: true, width: 50 },
    { name: 'name', title: 'Название', type: 'string', sortable: true, width: 200 },
    { name: 'city', title: 'Город', type: 'string', sortable: true, width: 200 },
    { name: 'residential_complex_id', title: 'ЖК', type: 'selectapi', api: 'mechti/residential_complex/select', editable },
    { name: 'ap_area', title: 'Площадь', type: 'number' },
    { name: 'ap_rooms', title: 'Комнат', type: 'string', sortable: true, width: 100 },
    { name: 'ap_price_buy', title: 'Ст-ть квартиры', type: 'number' },
    { name: 'type', title: 'Тип', type: 'select', sortable: true, width: 300, options: 'objectTypes' },
    { name: 'ads_data.versionAvito', title: 'Версия(Авито)', type: 'number', sortable: false, searchable: false, width: 80, editable },
    { name: 'ads_data.versionCian', title: 'Версия(Циан)', type: 'number', sortable: false, searchable: false, width: 80, editable },
    { name: 'ads_data.contactPhone', title: 'Тел.(Авито)', type: 'select', options: 'phone_list_ads', sortable: false, searchable: false, width: 150, editable },
    { name: 'ads_data.contactPhone_cian', title: 'Тел.(Циан)', type: 'select', options: 'phone_list_ads', sortable: false, searchable: false, width: 150, editable },
    { name: 'class', title: 'Класс', type: 'select', options: 'objectClasses', sortable: true, width: 300 },
    { name: 'status', title: 'Статус ', type: 'select', options: 'status_object', sortable: true, width: 100 },
    { name: 'status_site', title: 'Сайт', type: 'selectbit', sourceList: 'types', sortable: true, width: 100 },
  ],
  form: [
    { name: 'name', title: 'Название', type: 'string', validator: ['req'], autofocus: true },
    { name: 'source', title: 'Источник', type: 'comboboxapi', api: '/mechti/objects/tags/source', chips: false, validator: ['req'] },
    { name: 'city', title: 'Город', validator: ['req'], type: 'comboboxapi', api: '/mechti/objects/tags/city', chips: false },
    { name: 'address', title: 'Адрес', validator: ['req'], type: 'string' },
    { name: 'class', title: 'Класс', type: 'select', options: 'objectClasses' },
    { name: 'type', title: 'Тип', type: 'select', options: 'objectTypes', validator: ['req'] },
    { name: 'ap_price_buy', title: 'Стоимость квартиры', validator: ['req'], type: 'number' },
    { name: 'ap_area', title: 'Площадь', type: 'number', validator: ['req'] },
    { name: 'ap_rooms', title: 'Количество комнат', type: 'number' },
    { name: 'ap_ceiling_height', title: 'Высота потолков', type: 'number' },
    { name: 'ap_layout', title: 'Планировка', type: 'text' },
    { name: 'ap_level', title: 'Уровней', type: 'number' },
    { name: 'ap_floor', title: 'Этаж', type: 'number' },
    { name: 'ap_view', title: 'Вид из окна', type: 'text' },
    { name: 'infrastructure', title: 'Инфраструктура', type: 'comboboxapi', api: '/mechti/objects/tags/infrastructure', chips: true },
    { name: 'metro', title: 'Метро', type: 'comboboxapi', api: '/mechti/objects/tags/metro' },
    { name: 'nearby', title: 'в 5 мин доступности', type: 'comboboxapi', api: '/mechti/objects/tags/nearby', chips: true, default: [] },
    { name: 'description', title: 'Описание', type: 'text' },
    { name: 'photos', title: 'Фото галерея', type: 'images' },
    { name: 'photos_design', title: 'Дизайн-проект', type: 'images' },
    { name: 'docs', title: 'документация', type: 'files' },
    { name: 'status', title: 'Статус', type: 'select', options: 'status_object', default: 10 },
    { name: 'vendor', title: 'Продавец', from: 'data' },
    { name: 'client_id', title: 'Клиент', type: 'select_api', api: '/mechti/investors/select' },
    { name: 'investor_id', title: 'Инвестор', type: 'select_api', api: '/mechti/investors/select' },
    { name: 'status_site', title: 'Публиковать на', type: 'selectbit', sourceList: 'types' },
    { name: 'price_adv', title: 'Стоимость (фонарик)', type: 'number', formula: '(d)=>{return (d?.ap_price_buy + (d.ap_area || 0)* 200000) || `???`}' },
    { name: 'residential_complex_id', title: 'ЖК', type: 'selectapi', api: 'mechti/residential_complex/select' },
    { name: 'residential_type', title: 'тип недвижимости', type: 'select', options: 'residential_type' },
  ],

  config: {
    site: {
      title: 'Данные объекта',
      fields:
        'name,city,address,residential_complex_id,residential_type,class,type,ap_area,ap_rooms,ap_ceiling_height,ap_layout,ap_level,ap_floor,ap_view,price_adv,infrastructure,metro,nearby,description,photos,photos_design,doc,doc_adv,ap_price_sell,status_site',
      fieldsRO:
        'name,city,address,residential_complex_id,residential_type,class,type,ap_area,ap_rooms,ap_ceiling_height,ap_layout,ap_level,ap_floor,ap_view,price_adv,infrastructure,metro,nearby,description,photos,photos_design,doc,doc_adv,ap_price_sell',
      fieldsForm:
        'name,city,address,residential_complex_id#8,residential_type#4,class#6,type#6,ap_area#4,ap_rooms#4,ap_ceiling_height#4,ap_level#4,ap_floor#4,price_adv#4,ap_price_sell#4,ap_layout,ap_view,infrastructure,metro,nearby,description,photos,photos_design,doc,doc_adv',
    },
  },
};
export default site;
