/** @format */

export default {
  data() {
    return {
      blockHeight: 256,
      detailHeight: 300,
      contentHeight: 0,
    };
  },
  updated() {
    // console.log('updated autoHeightBlock');
    this.autoHeightBlock();
    this.autoHeightDetail();
  },
  watch: {
    contentHeight(v) {
      this.autoHeightDetail(v);
    },
  },
  computed: {
    styleDetailCard() {
      return `height: ${this.detailHeight}px; overflow: auto`;
    },
  },
  methods: {
    setContentHeight(e) {
      this.contentHeight = e;
    },
    autoHeightBlock() {
      if (this.$vuetify.breakpoint.name == 'xs') {
        this.blockHeight = 330;
        return;
      }
      let block = document.querySelector('.block_master');
      if (block && block.clientHeight) this.blockHeight = block.clientHeight;
    },
    getHeightBlock(n) {
      let h = this.blockHeight;
      if (this.$vuetify.breakpoint.name == 'xs') {
        h = 330;
        return h;
      }
      let block = document.querySelector(n);
      if (block && block.clientHeight) h = block.clientHeight;
      // console.log('getHeightBlock', n, h, block);
      return h;
    },
    autoHeightDetail(v) {
      if (this.$vuetify.breakpoint.name == 'xs') {
        this.detailHeight = 300;
        return;
      }
      let block = document.querySelector('.block_detail');
      if (block) {
        let height = window.innerHeight;
        let c = block.getBoundingClientRect();
        let h = height - c.top - 120;
        // console.log('detailHeight', height, v, c.top, h);
        this.detailHeight = h < 300 ? 300 : h;
      }
    },
  },
};
