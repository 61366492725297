<template>
  <div>
    <dialog-edit2 :value="value" @input="$emit('input')">
      <template v-slot:title>
        <small v-if="id"> ({{ id }})</small>
        {{ id ? title : "Новый документ '" + title + "'" }}
      </template>
      <a-loader v-if="!loaded" />
      <v-row v-else class="mt-1 pt-2" style="overflow-y: auto; overflow-x: hidden">
        <v-col v-for="(el, i) in modelForm" :key="i" cols="12" :sm="el.size" class="py-0">
          <div v-for="name in el.name.split('+')" :key="name">
            <a-form-model
              :ref="'field_' + name"
              v-model="data"
              :model="getFormModel([name], model)"
              :errors="errors"
              :config="{ dense: true, readonly }"
              @validate="
                validate($event);
                doWatch($event);
              "
              @fieldData="setFieldData($event)"
            />
          </div>
        </v-col>
      </v-row>
      <template v-slot:actions>
        <v-spacer></v-spacer>
        <a-btn-save :disabled="readonly" @click="submit()"></a-btn-save>
        <a-btn-delete v-if="canDelete && id" :disabled="readonly" @click="removeDialogShow = true"></a-btn-delete>
        <v-btn @click="$emit('input')">Закрыть</v-btn>
      </template>
    </dialog-edit2>
    <remove-dialog v-model="removeDialogShow" @remove="_remove(api, id)" :title="removeTitle" />
    <su-Data ref="suEdit" v-model="suEditShow" :api="api" :id="id" :activate="value" :dataEdit="data" @show="suEditShow = true" v-if="getAccess('suData')" />
  </div>
</template>

<script>
import { getForm, submitForm, removeEl, getAccess, genModel } from "@/components/mixings";

export default {
  components: {},
  mixins: [getForm, submitForm, removeEl, getAccess, genModel],
  props: {
    value: Boolean,
    id: Number,
    api: String,
    m: Object,
    title: String,
    config: Object,
    hasData: { type: Boolean, default: false },
    initData: { type: Object, default: () => {} },
    opt: { type: String, default: "" },
  },
  data() {
    return {
      model: [],
      idEdit: 0,
      suEditShow: false,
      removeDialogShow: false,
      type: null,
      loaded: false,
      options: [],
      watchModel: null,
      fieldData: {},
    };
  },
  computed: {
    editObject___del() {
      return false;
    },
    canDelete() {
      return this.opt.includes("delete");
    },
    readonly() {
      return this.opt.includes("readonly");
    },
    removeTitle() {
      return this.m?.removeTitle || "Вы действительно хотите удалить это?";
    },
  },
  created() {
    this.loaded = false;
    this.updateData(this.id);
    if (!this.id) {
      this.loaded = true; ///  this.type = 1;
      //  this.data.status = 0;
    }
  },
  watch: {
    initData1(d) {
      for (const name in d) {
        this.$set(this.data, name, d[name]);
        this.$set(this.errors, name, null);
      }
    },
  },
  methods: {
    setFieldData(e) {
      if (!e) return;
      if (this.watchModel) {
        for (const key in this.watchModel) {
          if (key == e.name) {
            let data = e.value;
            let v = this.watchModel[key];
            for (const field in v) {
              if (this.data.hasOwnProperty(field) || true) {
                if (v[field].type === "field") {
                  this.setData(field, data?.[v[field].value]);
                } else if (v[field].type === "value") {
                  this.setData(field, v[field].value);
                } else {
                  console.log("не известный тип сопоставления данных " + v[field].type, this.watchModel);
                }
              } else {
                console.log("не найдено поле для сопоставления данных " + field);
              }
            }
          }
        }
      }
    },

    afterFetchData(r) {
      // this.calcModel(this.data?.type);
      let t = 0;
      if (this.m?.config?.watch) {
        t = this.data?.[this.m.config.watch] || 0;
        //console.log("config", t, this.config);
      }
      this.getModel(t);
      this.loaded = true;
    },
    getModel(t) {
      console.log("get model", t);
      let config = this.config;
      if (t) config = JSON.parse(JSON.stringify(this.m?.config?.[t] || this.config));
      if (this.data.status == 2) config.fieldsRO = config.fields;
      this.watchModel = config?.watch || null;
      let model = this.calcModelConfig(config);
      if (true)
        model.forEach(el => {
          if (el.hiddenCreate && !this.data?.id) {
            el.hidden = true;
          }
        });
      this.fillFormFromModel(model);
      this.model = model;
      this.inputData(this.initData);
      console.log("model", { model, config });
    },
    afterSave(data, status) {
      if (status) this.$emit("input");
      let id = data.id;
      if (!this.id && this.m?.routeName) this.$router.push({ name: this.m.routeName, params: { id } });
    },
    doWatch(e) {
      if (e == this.m?.config?.watch) this.getModel(this.data?.[e]);
      //console.log("doWatch", e, this.config, this.model);
    },
    inputData(d = {}) {
      if (d)
        for (const name in d) {
          let m;
          if ((m = this.model.find(m => m.name == name))) {
            m.readonly = true;
          }
          this.$set(this.data, name, d[name]);
          this.$set(this.errors, name, null);
        }
    },
    async _remove(api, id) {
      if (!api || !id) return;
      await this.remove(api, id);
      this.$emit("input");
    },
  },
};
</script>
