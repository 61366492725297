/** @format */

const investors = {
  list: [
    { name: 'id', title: '#', type: 'id', sortable: true, width: 50 },
    { name: 'name', title: 'ФИО', type: 'string', sortable: true, width: 250 },
    { name: 'object_total', title: 'Всего объектов', type: 'string', sortable: true, width: 150 },
    { name: 'object_active', title: 'Активных объектов', type: 'string', sortable: true, width: 150 },
    { name: 'invested', title: 'Инвестиций', type: 'string', sortable: true, width: 150 },
    { name: 'earned', title: 'Заработал', type: 'string', sortable: true, width: 150 },
  ],
  form: [
    { name: 'name', title: 'ФИО', type: 'fio', validator: ['req'], class: 'flex sm4 px-1' },
    { name: 'passport', title: 'Паспортная информация', type: 'string' },
    { name: 'phone1', title: 'Телефон', type: 'phone', class1: 'flex sm6 px-1' },
    { name: 'phone2', title: 'Телефон 2', type: 'phone', class1: 'flex sm6 px-1' },
    { name: 'whatsapp', title: 'WhatsApp', type: 'string', class1: 'flex sm6 px-1' },
    { name: 'email', title: 'Email', type: 'string', validator: ['email'], class1: 'flex sm6 px-1' },
    { name: 'notes', title: 'Заметки', type: 'text' },
    { name: 'photo', title: 'Фото', type: 'images' },
    { name: 'docs', title: 'Файлы', type: 'files' },
  ],
};

export default investors;
