<template>
  <div>
    <dialog-edit2 :value="isShow" @input="close()">
      <template v-slot:title>
        {{ title }}
      </template>
      <a-loader v-if="!loaded" />
      <v-row v-else class="mt-1 pt-2" style="overflow-y: auto; overflow-x: hidden">
        <v-col v-for="(el, i) in model" :key="`${i}-${el.name}`" cols="12" :sm="el.size" class="py-0">
          <a-form-model :ref="'field_' + el.name" v-model="data" :model="[el]" :errors="errors" :config="{ dense: true, readonly }" @validate="validate($event)" />
        </v-col>
      </v-row>
      <template v-slot:actions>
        <v-spacer></v-spacer>
        <a-btn-save :disabled="readonly" @click="submit()"></a-btn-save>
        <v-btn @click="close()">Закрыть</v-btn>
      </template>
    </dialog-edit2>
  </div>
</template>

<script>
import { getForm, submitForm, removeEl, getAccess, genModel } from "@/components/mixings";

export default {
  components: {},
  mixins: [getForm, submitForm, removeEl, getAccess, genModel],
  props: {},
  data() {
    return {
      isShow: false,
      loaded: false,
      resolvePromise: undefined,
      rejectPromise: undefined,
      model: [],
      title: null,
    };
  },
  computed: {
    readonly() {
      return false;
    },
  },
  created() {},
  watch: {
    isShow(v) {
      if (v) {
      } else {
        this.resetForm();
      }
    },
  },
  methods: {
    resetForm() {
      this.loaded = false;
      this.data = {};
      this.errors = {};
      this.validators = {};
    },
    show(opts = {}) {
      console.log("show", opts);
      this.resetForm();
      this.title = opts?.title;
      const model = opts?.model || [];
      const data = opts?.data || {};
      this.fetchData(data, model);
      // Once we set our config, we tell the popup modal to open
      this.isShow = true;
      // Return promise so the caller can get results
      return new Promise((resolve, reject) => {
        this.resolvePromise = resolve;
        this.rejectPromise = reject;
      });
    },
    getObjectPath(o, path) {
      const p = path.split(".");
      let tmp;
      for (let i = 0; i < p.length; i++) {
        const el = p[i];
        if (i === 0) {
          tmp = JSON.parse(JSON.stringify(o || {}));
        }
        tmp = tmp?.[el];
      }
      return tmp;
    },
    fetchData(data, model) {
      console.log("fetchData", data, model);
      this.model = model;
      for (let i = 0; i < model.length; i++) {
        let n = model[i].name;
        let f = model[i].editField || n;
        let d = this.getObjectPath(data || {}, f);
        if (!this.data[n]) this.$set(this.data, n, null);
        this.data[n] = d;
        this.$set(this.errors, n, null);
      }
      this.fillFormValidators(model);
      this.loaded = true;
    },
    async submit() {
      if (await this.validateAll(this.data)) {
        this.loaded = false;
        let res = this.data;
        this.resolvePromise(res);
        this.isShow = false;
      }
    },
    close() {
      if (this.isShow) {
        this.isShow = false;
        this.loaded = false;
        this.resolvePromise(false);
      } else this.$emit("input");
    },
    postData() {},
  },
};
</script>
