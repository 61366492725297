import { render, staticRenderFns } from "./select.vue?vue&type=template&id=56880e3a&"
import script from "./select.vue?vue&type=script&lang=js&"
export * from "./select.vue?vue&type=script&lang=js&"
import style0 from "./select.vue?vue&type=style&index=0&id=56880e3a&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VSelect } from 'vuetify/lib/components/VSelect';
installComponents(component, {VAutocomplete,VSelect})
