/** @format */

const documentEstimate = {
  title: 'Сметы',
  routeName: 'estimate_view',
  accessName: 'estimate',
  operationsCash: [],
  api: '/accounting/doc/estimate',
  accDocName: 'AccountingDocEstimate',
  statuses: [
    { value: null, text: 'черновик', next: [2], action: 'Вернуть' },
    { value: 0, text: 'черновик', next: [2], action: 'Вернуть' },
    { value: 1, text: 'На проверке', next: [0, 3], action: 'Отправить' },
    { value: 2, text: 'Отправлено', next: [], action: 'Отправить' },
    { value: 3, text: 'Отказан', next: [2], action: 'Отказать' },
  ],
  types: [
    { value: 1, text: 'Лид' },
    { value: 2, text: 'Клиент' },
  ],
  list: [
    { name: 'id', title: 'id', type: 'number', sortable: true, width: 30, hidden: true },
    { name: 'code_doc', title: 'Номер', type: 'string', sortable: true, width: 50 },
    { name: 'date_doc', title: 'Дата', type: 'datetime', sortable: true, width: 120 },
    { name: 'type', title: 'Тип', type: 'select', sourceList: 'types', sortable: true, width: 50, access: { role: [-1, -10] } },
    { name: 'estimate_name', title: 'Название', type: 'string', sortable: false, width: 120 },
    {
      name: 'lead_id',
      title: 'Лид',
      type: 'selectapi',
      api: 'mechti/leads/select',
      sortable: true,
      width: 150,
      access: { role: [-1, -10] },
    },
    { name: 'client_id', title: 'Клиент', type: 'select', dir: 'client', sortable: true, width: 150, access: { role: [-1, -10] } },
    { name: 'object_id', title: 'Объект', type: 'select', dir: 'object', sortable: true, width: 150 },
    { name: 'object_class', title: 'Класс', type: 'select', options: 'rooms_classes', sortable: true, width: 50 },
    { name: 'object_square', title: 'Площадь объекта', type: 'number', sortable: true, width: 80 },
    {
      name: 'createdby_id',
      title: 'Автор',
      type: 'select',
      dir: 'user',
      filter: { type: 'select', name: 'createdby_id', dir: 'user' },
      sortable: true,
      width: 150,
      access: { role: [-1, -10] },
    },
    { name: 'status', title: 'Статус', type: 'select', sourceList: 'statuses', sortable: true, width: 150, access: { role: [-1, -10] } },
    { name: 'valid_date', title: 'Актуален до', type: 'datetime', sortable: true, width: 120 },
    { name: 'value', title: 'Сумма ', type: 'number', sortable: true, width: 80 },
  ],

  viewForm1: {
    fields: 'createdby_id,code_doc,date_doc,estimate_name,lead_id',
  },
  viewForm2: {
    fields: 'value,description,comments',
  },
  form: [
    { name: 'type', title: 'Тип', type: 'select', sourceList: 'types', sortable: true, width: 50 },
    { name: 'code_doc', title: 'Номер Документа', type: 'string', hiddenCreate: true },
    { name: 'date_doc', title: 'Дата документа', type: 'datestring', hiddenCreate: true },
    { name: 'estimate_name', title: 'Название', type: 'string' },
    { name: 'valid_date', title: 'Актуален до', type: 'datestring' },
    { name: 'client_id', title: 'Клиент', type: 'select', dir: 'client', autocomplete: true },
    { name: 'object_id', title: 'Объект', type: 'select', dir: 'object', autocomplete: true },
    {
      name: 'lead_id',
      title: 'Лид',
      type: 'selectapi',
      api_: 'mechti/leads/select',
      api: { type: 'post', api: '/report_sql', data: { name: 'getLeadsList', params: { type: 0 } } },
    },
    { name: 'object_class', title: 'Класс', type: 'select', options: 'rooms_classes' },
    { name: 'object_square', title: 'Площадь объекта', type: 'number', validator: ['req'] },
    { name: 'status', title: 'Статус', validator: ['req'], default: 0, hidden: true },
    { name: 'comments', title: 'Комментарий', type: 'text' },
    { name: 'createdby_id', title: 'Автор', type: 'select', dir: 'user', hiddenCreate: true, roleShow: [-1, -10] },
    { name: 'template_name', title: 'Тип коммерческого предлложения', type: 'select', options: 'template_promo' },
  ],
  listRooms: [
    { name: 'actions', width: 40, statusHide: 2 },
    { name: 'id', title: 'id', type: 'number', sortable: true, width: 55 },
    { name: 'name', title: 'Наименование', type: 'string', sortable: true, width: 300, style: 'white-space: normal;' },
    { name: 'room_type', title: 'Тип', type: 'select', options: 'room_type', sortable: true, width: 80 },
    { name: 'room_class', title: 'Класс', type: 'select', options: 'rooms_classes', sortable: true, width: 80 },
    { name: 'room_square', title: 'Площадь', type: 'number', sortable: true, width: 60 },
    { name: 'wall_height', title: 'H-стен', type: 'number', sortable: true, width: 60 },
    { name: 'wall_length', title: 'L-стен', type: 'number', sortable: true, width: 60 },
    { name: 'comments', title: 'Комментарий', type: 'text' },
  ],
  formRooms: [
    { name: 'name', title: 'Наименование', type: 'string', validator: ['req'] },
    { name: 'room_type', title: 'Тип', type: 'select', options: 'room_type', validator: ['req'] },
    { name: 'room_class', title: 'Класс', type: 'select', options: 'rooms_classes', validator: ['req'] },
    { name: 'room_square', title: 'Площадь', type: 'number', validator: ['req'] },
    { name: 'wall_height', title: 'H-стен', type: 'number' },
    { name: 'wall_length', title: 'L-стен', type: 'number' },
    { name: 'comments', title: 'Комментарий', type: 'text' },
  ],
  listGoods: [
    { name: 'actions', width: 40, statusHide: 2 },
    { name: 'id', title: 'id', type: 'number', sortable: true, width: 55 },
    {
      name: 'room_id',
      title: 'Комната',
      type: 'selectapi',
      api: {
        type: 'post',
        api: '/report_sql',
        data: {
          name: 'getEstimateRoomList',
          params: { parent_id: '(function(d) {return d.parent_id;})', id: '(function(d) {return d.room_id ? d.room_id : -2 ;})' },
        },
      },
      itemField: { value: 'id', text: 'name' },
    },
    { name: 'data.name', title: 'Наименование', type: 'string', hidden: true },
    {
      name: 'good_id',
      title: 'Наименование',
      type: 'selectapi',
      api: 'mechti/goods_type',
      itemField: { text: 'name', value: 'id' },
      sortable: true,
      width: 300,
      style: 'white-space: normal;',
    },
    { name: 'goods_class', title: 'Класс', type: 'select', options: 'rooms_classes' },
    { name: 'price', title: 'Цена', type: 'number', sortable: true, width: 50 },
    { name: 'amount', title: 'Кол-во', type: 'number', sortable: true, width: 50 },
    { name: 'value', title: 'Стоимость', type: 'number', sortable: true, width: 80 },
    { name: 'comments', title: 'Коментарий', type: 'text' },
  ],
  formGoods: [
    {
      name: 'room_id',
      title: 'Комната',
      type: 'selectroom',
      api: {
        type: 'post',
        api: '/report_sql',
        data: {
          name: 'getEstimateRoomList',
          params: { parent_id: '(function(d) {return d.parent_id;})', id: '(function(d) {return -1 ;})' },
        },
      },
    },

    {
      name: 'good_id',
      title: 'Тип товара',
      type: 'selectapi',
      api: '/mechti/goods_type',
      filters: { status: 1 },
      sort: { key: 'name', order: 'ASC' },
      itemField: { text: 'name', value: 'id' },
      check: true,
    },
    {
      name: 'category_id',
      title: 'Категория товара',
      type: 'select',
      dir: 'category',
      api: 'accounting/dir/service_category/select',
      filters: {
        type: 2,
        OR: [
          { field: 'status', condition: '<>', value: 2 },
          { field: 'status', condition: 'IS', value: null },
        ],
      },
      readonly: true,
      check: true,
    },
    //{ name: 'name', title: 'Наименование', validator: ['req'], type: 'string', type1: 'comboboxapi', api: '/mechti/construction-goods/tags/name' },
    { name: 'goods_class', title: 'Класс', type: 'select', options: 'rooms_classes', size: 3 },
    { name: 'data.price_comfort', title: 'Стоп-цена(комфорт)', type: 'number', readonly: true, size: 3 },
    { name: 'data.price_business', title: 'Стоп-цена(бизнес)', type: 'number', readonly: true, size: 3 },
    { name: 'data.price_premium', title: 'Стоп-цена(премиум)', type: 'number', readonly: true, size: 3 },
    { name: 'amount', title: 'Кол-во', type: 'number', default: 1, size: 4, validator: ['req'] },
    { name: 'price', title: 'Цена', type: 'number', size: 4 },
    { name: 'value', title: 'Стоимость', type: 'number', formula: '(function(d) {return d.price*d.amount ;})', readonly: true, size: 4 },
    { name: 'comments', title: 'Комментарий', type: 'text' },
  ],
  listWorks: [
    { name: 'actions', width: 40, statusHide: 2 },
    { name: 'id', title: 'id', type: 'number', sortable: true, width: 55 },
    {
      name: 'room_id',
      title: 'Комната',
      type: 'selectapi',
      api: {
        type: 'post',
        api: '/report_sql',
        data: {
          name: 'getEstimateRoomList',
          params: { parent_id: '(function(d) {return d.parent_id;})', id: '(function(d) {return d.room_id ? d.room_id : -2 ;})' },
        },
      },
      itemField: { value: 'id', text: 'name' },
      formula1: '((d)=>{console.log("get room name", d);return d.data_table[0].find((r)=>r.id==value)?.name})',
    },
    {
      name: 'service_id',
      title: 'Наименование',
      type: 'select',
      dir: 'service',
      dirFilter: { category_id: [28] },
      sortable: true,
      width: 300,
      style: 'white-space: normal;',
    },
    { name: 'works_class', title: 'Класс', type: 'select', options: 'rooms_classes', size: 3 },

    { name: 'price', title: 'Цена', type: 'number', sortable: true, width: 50 },
    { name: 'amount', title: 'Коэф.', type: 'number', sortable: true, width: 50 },
    { name: 'value', title: 'Стоимость', type: 'number', sortable: true, width: 80 },
    { name: 'comments', title: 'Коментарий', type: 'text' },
  ],
  formWorks: [
    {
      name: 'room_id',
      title: 'Комната',
      type: 'selectapi',
      api: {
        type: 'post',
        api: '/report_sql',
        data: {
          name: 'getEstimateRoomList',
          params: { parent_id: '(function(d) {return d.parent_id;})', id: '(function(d) {return d.room_id && false ? d.room_id : -1 ;})' },
        },
      },
      itemField: { value: 'id', text: 'name' },
      formula1: '((d)=>{console.log("get room name", d);return d.data_table[0].find((r)=>r.id==value)?.name})',
    },
    {
      name: 'service_id',
      title: 'Наименование',
      type: 'selectapi',
      filters: { category_id: [28], status: 1 },
      api: 'accounting/dir/service',
      itemField: { text: 'name', value: 'id' },

      dir: 'service',
      dirFilter: { category_id: [28] },
    },
    { name: 'works_class', title: 'Класс', type: 'select', options: 'rooms_classes', size: 6 },
    { name: 'data.square', title: 'Площадь', type: 'number', readonly: true, size: 6 },
    { name: 'data.price_comfort', title: 'Стоп-цена(комфорт)', type: 'number', readonly: true, size: 4 },
    { name: 'data.price_business', title: 'Стоп-цена(бизнес)', type: 'number', readonly: true, size: 4 },
    { name: 'data.price_premium', title: 'Стоп-цена(премиум)', type: 'number', readonly: true, size: 4 },
    { name: 'price', title: 'Цена', type: 'number', size: 4 },
    { name: 'amount', title: 'Коэффициент', type: 'number', default: 1, size: 4 },
    {
      name: 'value',
      title: 'Стоимость',
      type: 'number',
      formula: '(function(d) {return d.price*d.amount*(d?.data?.square || 1) ;})',
      readonly: true,
      size: 4,
    },
    { name: 'comments', title: 'Коментарий', type: 'text' },
  ],
  config: {
    watch: 'type',
    default: {
      fields: 'type,status',
      fieldsRO: '',
      fieldsForm: 'type',
    },
    //смета для лида
    1: {
      fields: 'type,lead_id,object_square,comments,estimate_name,valid_date,template_name,status,object_class',
      fieldsRO: '',
      fieldsForm: 'type,estimate_name,lead_id,object_class,object_square,valid_date,comments,template_name',
    },
    //смета для клиента
    2: {
      fields: 'type,client_id,object_id,comments,estimate_name,valid_date,template_name,status,object_class,object_square',
      fieldsRO: '',
      fieldsForm: 'type,client_id,object_id#6,object_class#3,object_square#3,valid_date,comments,template_name',
    },
  },
};
export default documentEstimate;
