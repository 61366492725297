/** @format */

import fillForm from './fillForm';

export default {
  mixins: [fillForm],
  data() {
    return {
      loading: false,
      permit: true,
    };
  },
  methods: {
    updateData(id) {
      this.resetFrom();
      this.fillForm();
      this.fetchData(id);
    },
    setData(name, value) {
      let field = '';
      let path = name.split('.');
      if (path.length == 2) {
        field = path[0];
        name = path[1];
      }
      //      let field = el?.jsonField || 'data';
      if (field) {
        let data = JSON.parse(JSON.stringify(this.data[field]));
        data[name] = value;
        this.data[field] = data;
      } else this.data[name] = value;
    },
    async fetchData(id) {
      if (id) {
        this.loading = true;
        let response;
        try {
          response = await this.$axios.get(this.api + '/' + id);
          let data = response.data.data;

          for (const name in data) {
            if (name == 'data') {
            }
            if (name == 'data' && this.data?.data && data[name]) {
              for (const nameData in data.data) {
                this.$set(this.data.data, nameData, data.data[nameData]);
                this.$set(this.errors, nameData, null);
              }
            } else if (name == 'data' && typeof this.data.data == 'object') {
            } else {
              this.$set(this.data, name, data[name]);
              this.$set(this.errors, name, null);
            }
          }
        } catch (error) {}
        this.loading = false;

        this.afterFetchData(response);
      } else this.afterFetchData();
    },
    afterFetchData(response) {},
  },
};
