<template>
  <div>
    <dialog-edit :value="value" @input="$emit('input')">
      <template v-slot:title> Копирование свойств </template>
      <v-card-text style="height: 80vh; overflow: auto" class="pa-0">
        <a-table-f-sql ref="table" :query="sql" :model="model" :useQuery="false" :defaults="{ paramName1: 'goods.goodsIncome', sort: {} }" @click="clickRow" />
      </v-card-text>
      <template v-slot:actions>
        <v-spacer></v-spacer>
        <a-btn-save @click="add()" :title="'Скопировать выбранное'" />
        <v-btn @click="$emit('input')">Закрыть</v-btn>
      </template>
    </dialog-edit>
  </div>
</template>

<script>
import { getAccess, genModel } from "@/components/mixings";

export default {
  mixins: [getAccess, genModel],
  props: {
    value: Boolean,
    id: Number,
    type: String,
    api: String,
    defaults: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      m: this.$store.getters["config/get"].models.objects,
      loaded: false,
      modelForm: null,
      parent: "-1",
      model: [
        { name: "checked", type: "checkbox", width: 35 },
        { name: "note", title: "#", type: "string", style: "white-space: normal;", sortable: true, width: 50 },
        { name: "folder", title: "", type: "folder", style: "white-space: normal;", sortable: true, width: 50 },

        { name: "object_name", title: "Объект", type: "string", style: "white-space: normal;", sortable: true, width: 150 },
        { name: "project_name", title: "Дизайн-проект", type: "string", style: "white-space: normal;", sortable: true, width: 150 },
        { name: "prop_name", title: "Свойство", type: "string", style: "white-space: normal;", sortable: true, width: 150 },
        { name: "value", title: "Название", type: "select", field: "options", style: "white-space: normal;", sortable: true, width: 150 },
      ],
      dataTable: [],
      sql: {},
    };
  },
  computed: {},
  watch: {
    value(v) {
      this.parent = -1;
      if (v) this.getData();
    },
  },
  created() {
    // this.getData();
  },
  methods: {
    getData() {
      let id = this.id;
      let sql = `
select tbl.*,
  o.name AS object_name, p.name as project_name,
  isparent as check_disable,
  if(old_id is null, '','уже есть') as note
 from (
SELECT 0 as id, opv.object_type, opv.object_id as table_id,
  0 as isparent,
  concat(opv.object_type,'-',opv.object_id) as parent_id,
	op.name AS prop_name,
	op.options,
	dst.id  AS old_id,
	opv.value, opv.object_props_id, opv.object_id, opv.data, opv.id as import_id
FROM object_props_value opv
	LEFT JOIN object_props op ON op.id=opv.object_props_id
	LEFT JOIN object_props_value dst ON dst.object_id=${id} AND dst.value=opv.value AND opv.object_props_id=dst.object_props_id and dst.object_type='${this.type}'
WHERE ifnull(opv.object_id,0)<>${id}

union all
select distinct object_id as id, object_type, object_id  as table_id,
  1 as isparent,
  concat(object_type,'-',object_id) as parent_id,   NULL,NULL,NULL,NULL,NULL,NULL,NULL,NULL
from object_props_value
WHERE not(object_id=${id} and object_type='${this.type}')) as tbl
	LEFT JOIN objects o ON o.id=tbl.table_id and tbl.object_type='objects'
	LEFT JOIN design_projects p ON p.id=tbl.table_id  and tbl.object_type='design_projects'
where (isparent=1 or parent_id='${this.parent}')

`;
      this.sql = { sql, order: " parent_id , isparent desc, prop_name", filter11: { isparent: 1 } };
    },
    clickRow(d) {
      if (d.row.isparent) {
        this.parent = this.parent != d.row.parent_id ? d.row.parent_id : -1;
        this.getData();
        return;
      }
      this.$refs.table.$refs.base.clickCheckbox(d.event, d.row);
      this.$refs.table.$refs.base.$forceUpdate();
    },
    add() {
      let rows = [];
      let order = null;

      for (let row of this.$refs.table.data.filter((el) => {
        return el?.checked;
      })) {
        rows.push({
          id: row.old_id,
          object_type: this.type,
          object_id: this.id,
          object_props_id: row.object_props_id,
          value: row.value,
          data: JSON.parse(row.data),
        });
      }
      if (rows.length == 0) return;
      this.$emit("save", rows);
      this.$emit("input");
    },
    afterSave(data, status) {
      if (status) this.$emit("input");
      // this.$router.push({name: "objects_view", params: {id: this.id}});
    },
  },
};
</script>
