<template>
  <div class="py-1 pb-3">
    <div>
      <a-loader v-if="!loaded" />
      <v-row v-else class="mt-1 pt-2" style="overflow-y: auto; overflow-x: hidden">
        <v-col v-for="(el, i) in modelForm" :key="i" cols="12" :sm="el.size" class="py-0">
          <div v-for="name in el.name.split('+')" :key="name">
            <a-form-model
              :ref="'field_' + name"
              v-model="data"
              :model="getFormModel([name], model)"
              :errors="errors"
              :config="{ dense: true, readonly, hideDetails: 'auto' }"
              @validate="validate($event)"
            />
          </div>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import { getForm, submitForm, removeEl, getAccess, genModel } from "@/components/mixings";

export default {
  components: {},
  mixins: [getForm, submitForm, removeEl, getAccess, genModel],
  props: {
    value: { type: Object, default: () => {} },
    values: Object,
    opt: { type: String, default: "" },
  },
  data() {
    return {
      m1: {
        title: "Взятие займа",
        form: [
          { name: "versionAvito", title: "Версия(Авито)", type: "number", json: true },
          { name: "versionCian", title: "Версия(Циан)", type: "number", json: true },
          {
            name: "bet",
            title: "Ставка",
            type: "selectvalues",
            api: "/mechti/cian_bet",
            fields: { title: "city", values: "value_standart,value_premium,value_top" },
            options: "ads_bet",
            fields1: { title: "city", values: "value1,value2,value3" },
            optionsFilter: { city: "(function(e,d,v) { return e==v?.object_data?.city})" },
            json: true,
          },
          { name: "contactPhone", title: "Номер телефона (Авито)", type: "select", options: "phone_list_ads", json: true },
          { name: "contactPhone_cian", title: "Номер телефона (Циан)", type: "select", options: "phone_list_ads", json: true },
          { name: "description", title: "Описание (Авито)", type: "text", rows: 2, json: true },
          { name: "description_cian", title: "Описание (Циан)", type: "text", rows: 2, json: true },
          { name: "latitude", title: "Широта", type: "number", json: true },
          { name: "longitude", title: "Долгота", type: "number", json: true },
          { name: "metro", title: "Метро рядом", type: "select", dir: "metro", chips: true, maxCountValue: 3, autocomplete: true, json: true },
          { name: "isDefaultLayout", title: "Планировка по умолчанию", type: "select", options: "yesNo", default: 0, json: true },
          { name: "layoutPhoto", title: "Фото планировки (Общие)", type: "imagesdrag", json: true },
          { name: "photos", title: "Другие фотографии (Общие)", type: "imagesdrag", json: true },
          { name: "photos_avito", title: "Доп. фотографии (Авито)", type: "imagesdrag", json: true },
          { name: "videoURL", title: "Ссылка на видео", type: "string", json: true },
          { name: "cadastralNumber", title: "Кадастровый номер", type: "string", json: true },
          { name: "price", title: "Цена", type: "number", json: true },
          { name: "houseType_", title: "Тип здания", type: "select", options: "Кирпичный,Панельный,Блочный,Монолитный,Монолитно-кирпичный".split(","), json: true },
          { name: "passengerElevator", title: "Пассажирские лифты", type: "number", default: 0, json: true },
          { name: "freightElevator", title: "Грузовые лифты", type: "number", default: 0, json: true },
          { name: "balconies", title: "Балконы", type: "number", default: 0, json: true },
          { name: "loggias", title: "Лоджии", type: "number", default: 0, json: true },
          { name: "wardrobe", title: "Гардеробная", type: "select", options: "yesNo", default: 0, json: true },
          { name: "windows", title: "Панорамные окна", type: "select", options: "yesNo", default: 0, json: true },
          { name: "separateWcsCount", title: "Раздельные саузлы", type: "number", default: 0, json: true },
          { name: "combinedWcsCount", title: "Совмещенные санузлы", type: "number", default: 0, json: true },
          {
            name: "parking",
            title: "Парковка",
            type: "select",
            chips: true,
            options: "Подземная,Наземная многоуровневая,Открытая во дворе,За шлагбаумом во дворе".split(","),
            json: true,
          },
          { name: "repair_type", title: "Тип ремонта", type: "select", options: "repair_type", json: true },
        ],
        config: {
          default: {
            fields:
              "versionAvito,versionCian,metro,bet,parking,contactPhone,contactPhone_cian,description,description_cian,latitude,longitude,isDefaultLayout,layoutPhoto,photos,photos_avito,videoURL,cadastralNumber,price,houseType,passengerElevator,freightElevator,balconies,loggias,wardrobe,windows,separateWcsCount,combinedWcsCount,repair_type",
            fieldsRO: "",
            fieldsForm:
              "versionAvito#6,versionCian#6,bet,contactPhone#6,contactPhone_cian#6,description,description_cian,latitude#6,longitude#6,metro,repair_type,isDefaultLayout,layoutPhoto,photos,photos_avito,videoURL,cadastralNumber#6,price#6,passengerElevator#4,freightElevator#4,balconies#4,loggias#4,wardrobe#4,windows#4,separateWcsCount#4,combinedWcsCount#4,parking",
          },
        },
      },
      model: [],
      idEdit: 0,
      suEditShow: false,
      removeDialogShow: false,
      type: null,
      loaded: false,
      options: [],
      watchModel: null,
      fieldData: {},
    };
  },
  computed: {
    m() {
      return this.m1;
      return res;
    },
    canDelete() {
      return this.opt.includes("delete");
    },
    readonly() {
      return this.opt.includes("readonly");
    },
    removeTitle() {
      return this.m?.removeTitle || "Вы действительно хотите удалить это?";
    },
  },
  created() {
    this.loaded = false;
    this.updateData(this.id);
    if (!this.id) {
      this.loaded = true; ///  this.type = 1;
      //  this.data.status = 0;
    }
  },
  watch: {
    value(v) {
      this.inputData(this.value);
    },
  },
  methods: {
    validate(e) {
      this.$emit("input", this.data);
    },
    afterFetchData(r) {
      // this.calcModel(this.data?.type);

      this.getModel();
      this.loaded = true;
    },
    getModel(t = "default") {
      let config = JSON.parse(JSON.stringify(this.m.config.default || this.config));
      let model = this.calcModelConfig(config);

      this.fillFormFromModel(model);
      this.model = model;
      this.inputData(this.value);
    },
    afterSave(data, status) {
      if (status) this.$emit("input");
      let id = data.id;
      if (!this.id && this.m?.routeName) this.$router.push({ name: this.m.routeName, params: { id } });
    },

    inputData(d = {}) {
      if (this.values) {
        this.$set(this.data, "object_data", this.values?.object_data || {});
      }
      if (d)
        for (const name in d) {
          this.$set(this.data.data, name, d[name]);
          this.$set(this.errors, name, null);
        }
    },
    async _remove(api, id) {
      if (!api || !id) return;
      this.$emit("input");
    },
  },
};
</script>
