/** @format */

export default {
  data() {
    return {
      cashType: null,
      configDoc: null,
    };
  },
  methods: {
    onConfigDoc(type, model = null) {
      this.cashType = null;
      if (!type) return false;
      if (type == -1 && this.$root.profile.role !== 1000) return false;
      let operation = this.$root.dirs.operationTable.find((el) => el.id == type);
      if (!operation && type !== -1) {
        return false;
      }
      let m;
      const m_out = this.$store.getters['config/get'].models.cashOut;
      const m_in = this.$store.getters['config/get'].models.cashIn;

      if (m_out.config.find((el) => el.id == type) && model !== 'AccountingDocOrderReceiptModel') {
        //m = JSON.parse(JSON.stringify(m_out));
        m = Object.assign({}, m_out);
        console.log('out');
      } 
      if (m_in.config.find((el) => el.id == type) && model !== 'AccountingDocOrderWithdrawalModel') {
        console.log('in');
        //m = JSON.parse(JSON.stringify(m_in));
        m = Object.assign({}, m_in);
      }
      let config = m.config.find((el) => el.id == type);
      //console.log('config', config);

      if (!config?.template) {
        console.error('config template not found');
        return false;
      }
      config.title = m.title;
      config.m = m;
      // console.log('on config doc', config.template, { type, config, operation });
      this.cashType = config.template;
      this.configDoc = Object.assign({}, operation, config);
      return true;
    },

    onConfigDocOut(type) {
      this.cashType = null;
      if (!type) return false;
      if (type == -1 && this.$root.profile.role !== 1000) return false;
      let operation = this.$root.dirs.operationTable.find((el) => el.id == type);
      if (!operation && type !== -1) {
        return false;
      }
      const m = Object.assign({}, this.$store.getters['config/get'].models.cashOut);
      let config = m.config.find((el) => el.id == type);
      if (!config?.template) {
        return false;
      }
      config.title = m.title;
      config.m = m;
      this.cashType = config.template;
      this.configDoc = Object.assign(config, operation);
      return true;
    },
    onConfigDocIn(type) {
      this.cashType = null;
      if (!type) return false;
      if (type == -1 && this.$root.profile.role !== 1000) return false;
      let operation = this.$root.dirs.operationTable.find((el) => el.id == type);
      if (!operation && type !== -1) {
        return false;
      }
      const m = Object.assign({}, this.$store.getters['config/get'].models.cashIn);
      let config = m.config.find((el) => el.id == type);
      if (!config?.template) {
        return false;
      }
      config.title = m.title;
      config.m = m;
      this.cashType = config.template;
      this.configDoc = Object.assign(config, operation);
      return true;
    },
  },
};
