/** @format */

const payOrder = {
  title: 'Заявка на оплату',
  routeName: 'payOrder_view',
  accessName: 'payOrder',
  isDocument: false,
  api: '/mechti/pay_orders',
  statuses: [
    { value: 0, text: 'Новая заявка', next: [1, 3] },
    { value: 1, text: 'Запланировано', next: [2, 3, 4, -1], action: 'Запланировать' },
    { value: 2, text: 'Выполнена', next: [], action: 'Исполнена', rules: { formula: '(d)=>{return !(d.payment_type===1 && d.status!==4 ) }' } },
    { value: 4, text: 'Ожидает импорт', next: [-1, 2], action: 'Оплачено (ожидает импорт)', rules: { formula: '(d)=>{return d.payment_type==1}' } },
    { value: 3, text: 'Отменена', next: [-1], action: 'Отменить' },
    { value: -1, text: 'Новая заявка', setValue: 0, action: 'Вернуть в новые' },
  ],
  optionsIsImportant: [
    { value: 1, text: 'Низкий', color1: 'rgb(190, 190, 190)' },
    { value: 2, text: 'Стандартный', color1: '#626262' },
    { value: 3, text: 'Высокий', color1: '#FFB914' },
    { value: 4, text: 'Блокирующий', color1: '#E97678' },
  ],

  paymentTypes: [
    { value: 1, text: 'Безналичная оплата' },
    { value: 2, text: 'Пополнение карты' },
    { value: 3, text: 'Оплата из кассы' },
  ],
  types: [
    { value: 'orderGoodsPayment', text: 'Оплата заказа товаров', color: '#e76a6c', hidden: !true, bgColor1: '#e76a6c' },
    { value: 'orderServicePayment', text: 'Оплата заказа услуг', color: '#e76a6c', hidden: !true, bgColor1: '#e76a6c' },
  ],
  form: [
    { name: 'name', title: 'Заголовок', type: 'string', validator: ['req'], setReadonly: true },
    { name: 'description', title: 'Описание', type: 'text', setReadonly: true },
    { name: 'type', title: 'Тип задачи', type: 'select', sourceList: 'types', validator: ['req'] },
    { name: 'date_start', title: 'Плановая дата платежа', type: 'datestring', validator: ['req1'] },
    { name: 'date_end', title: 'Оплатить до', type: 'datestring', validator: ['req'] },
    { name: 'is_important', title: 'Приоритет', type: 'select', default: 2, sourceList: 'optionsIsImportant' },
    { name: 'status', title: 'Статус', validator: ['req'], sourceList: 'statuses', default: 0 },
    { name: 'payment_type', title: 'Тип оплаты', type: 'select', sourceList: 'paymentTypes', validator: ['req'], default: 1 },

    { name: 'amount', title: 'Сумма', type: 'number' },
    {
      name: 'creator_name',
      title: 'Кем создана',
      type: 'string',
      filter: { type: 'selectapi', name: 'createdby_id', api: '/manager/users/select', filters: { status: 1 } },
      hidden: true,
    },
    { name: 'createdon', title: 'Дата создания', type: 'datetime', hidden: true },
    { name: 'filesRemove', default: [], hidden: true },
    { name: 'filesAdd', default: [], hidden: true },
    { name: 'docs', title: 'Документы', type: 'filesdata', category: 1, hiddenCreate: !true },
    { name: 'object_name', title: 'Объект', type: 'string', calculated: true, formula: '((d) => {return d.parent?.object_c?.name ;})' },
  ],
  list: [
    { name: 'id', title: '#', type: 'id', sortable: true, width: 60, hidden: false },
    { name: 'createdon', title: 'Дата', type: 'datetime', sortable: true, width: 150, hidden: !true },
    {
      filter: { type: 'select', name: 'is_important', sourceList: 'optionsIsImportant' },
      name: 'is_important',
      title: 'Приоритет',
      type: 'select',
      width: 90,
      sourceList: 'optionsIsImportant',
      hidden: !true,
    },
    { name: 'name', title: 'Задача', type: 'string', sortable: true, filter: { type: 'text', name: 'name' }, style: 'white-space: normal;', width: 250, hidden: true },
    { name: 'type', sourceList: 'types', title: 'Тип', type: 'select', width: 130, badgeField1: 'unviewedscomments_total', isMenu: true },
    { name: 'payment_type', title: 'Тип оплаты', type: 'select', sourceList: 'paymentTypes' },
    { name: 'amount', title: 'Сумма', type: 'number', sortable: true, width: 100 },
    { name: 'date_start', title: 'Дата план', type: 'date', sortable: true, width: 100 },
    { name: 'date_end', title: 'Дата до..', type: 'date', sortable: true, width: 100 },
    { name: 'object_name', filter: { type: 'select', name: 'object_id', dir: 'object' }, sortable: true, title: 'Объект', type: 'string', width: 150, isMenu: true },
    {
      name: 'creator_name',
      title: 'Кем создана',
      type: 'string',
      filter: { type: 'selectapi', name: 'createdby_id', api: '/manager/users/select', filters: { status: 1 } },
      sortable: true,
      width: 150,
    },
    { name: 'status', title: 'Статус', type: 'select', sortable: true, width: 150, sourceList: 'statuses', style: 'white-space: normal;' },
    { name: 'count_id', title: 'Показано', hidden: true },
    { name: 'sum_value', title: 'Сумма', hidden: true },
  ],
  viewForm1: {
    fields: 'name,type,payment_type,createdon,creator_name,description',
  },
  viewForm2: {
    fields: 'object_name,is_important,amount,date_end,date_start',
  },
  config: {
    default: {
      title: 'Заявка на оплату',
      fields: 'name,type,createdon,creator_name,amount,date_start,is_important,docs,description,filesAdd,status,date_end,payment_type',
      fieldsRO: 'createdon,creator_name,is_important',
      fieldsForm: 'name,type#5,payment_type#5,is_important#2,createdon#6,creator_name#6,amount#4,date_start#4,date_end#4,description,docs',
    },
    create: {
      title: 'Новая заявка на оплату',
      fields: 'name,type,createdon,creator_name,amount,is_important,docs,description,filesAdd,status,date_end,payment_type',
      fieldsRO: 'createdon,creator_name,is_important',
      fieldsForm: 'name,type#5,payment_type#5,is_important#2,createdon#6,creator_name#6,amount#6,date_end#6,description,docs',
    },
  },
};
export default payOrder;
